import React from "react";
import dashboard from "../image/sidebar-icon/Dashboard.svg";
import logo from "../image/logo/logo.png";
import sell_token from "../image/sidebar-icon/homepage.svg";
import buy_token from "../image/sidebar-icon/buy-token.svg";
import withdraw from "../image/sidebar-icon/binance-logo.svg";
import whitepaper from "../image/sidebar-icon/whitepaper.svg";
import refer from "../image/sidebar-icon/refer.svg";
import audit from "../image/sidebar-icon/audit.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RiMenu4Line } from "react-icons/ri";

const Saidbar = () => {
  let navigate = useNavigate();

  const [activeLink, setActiveLink] = useState("/");
  const location = useLocation();

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  const handleLogout = () => {
    // Clear user session (e.g., localStorage, sessionStorage, cookies)
    localStorage.removeItem('token'); // Example if token is stored in localStorage
    sessionStorage.clear(); // Clear sessionStorage if used
    

    // Redirect to login or home page
    window.location.reload()
  };


  return (
    <div>
      <div className="sidebar p-md-3">
        <div className="navbarnone">
          <nav className="navbar navbar-expand-md ">
            <div className="logo">
              <div className="togglebtn">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                >
                  <span className="navbar-toggler-icon">
                    <RiMenu4Line
                      style={{
                        fontSize: 29,
                        color: "#3267e3",
                        fontWeight: 900,
                      }}
                    />
                  </span>
                </button>
              </div>
              <div className="logos_menu">
                <h4 className="desktop-logo " href="#">
                  {" "}
                  <img src={logo} alt="Logo" className="" />{" "}
                </h4>
                <h4 className="mobile-logo" href="#">
                  {" "}
                  <img src={logo} alt="Logomobile" />{" "}
                </h4>
              </div>
            </div>
            <div className="menubar w-100 d-flex justify-content-center align-content-center">
              <div className="menucontant w-100 ">
                <div
                  className="collapse navbar-collapse"
                  id="collapsibleNavbar"
                >
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={() => handleLinkClick("/")}
                  >
                    <img width={"20"} src={dashboard} alt="dasicon" />
                    Dashboard
                  </Link>
                  <Link
                    to="/Swap"
                    onClick={() => handleLinkClick("/Swap")}
                  >
                    <img width={"20"} src={sell_token} alt="rewicon" />
                    Avtar Swap
                  </Link>
                  {/* <Link
                    to="/Kyc"
                    onClick={() => handleLinkClick("/Swap")}
                  >
                    <img width={"20"} src={sell_token} alt="rewicon" />
                   Kyc
                  </Link> */}
                  <a
                    target="_blank"
                    href="https://bscscan.com/token/0x6cf243259FFf2bb24F1a1b63423C157d522fA069"
                  >
                    <img width={"20"} src={withdraw} alt="rewicon" /> Avtar
                    Contract
                  </a>
                  <a
                    target="_blank"
                    href="https://bscscan.com/address/0xe4674f929b8023b711e76f351f7906727d645a6c"
                  >
                    <img width={"20"} src={withdraw} alt="rewicon" /> Baaz
                    Contract
                  </a>
                  <hr className="hr-tag" />
                  <a href="/" download="">
                    <img width={"20"} src={audit} alt="rewicon" /> Avtar PDF
                  </a>
                  <Link
                    onClick={handleLogout}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" /> Logout
                    
                  </Link>

                  {/* <a href="https://copywing.io/">
                    <img width={"20"} src={sell_token} alt="rewicon" /> Homepage
                  </a> */}

                  {/* <a href="/" download="CUNWhitepaper.pdf">
                    <img width={"20"} src={whitepaper} alt="rewicon" /> Setting
                  </a> */}

                  {/* 
                  <Link
                    to="/level-rewards"
                    className={
                      location.pathname === "/level-rewards" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/level-rewards")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" /> Level
                    Rewards
                  </Link>
                  

                  <Link
                    to="/LedarshipRewardList"
                    className={
                      location.pathname === "/LedarshipRewardList" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/LedarshipRewardList")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" />
                    Leadership Reward
                  </Link>

                  <Link
                    to="/DairectIncome"
                    className={
                      location.pathname === "/DairectIncome" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/DairectIncome")}
                  >
                    <img width={"20"} src={buy_token} alt="rewicon" />
                    Direct Income
                  </Link>

                  <Link
                    to="/daily-rewards"
                    className={
                      location.pathname === "/daily-rewards" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/daily-rewards")}
                  >
                    <img width={"20"} src={sell_token} alt="rewicon" /> Daily
                    Rewards
                  </Link>

                  <Link
                    to="/team-bonus"
                    className={
                      location.pathname === "/team-bonus" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/team-bonus")}
                  >
                    <img width={"20"} src={refer} alt="rewicon" /> Team Bonus
                  </Link>

                  <Link
                    to="/withdrawal-list"
                    className={
                      location.pathname === "/withdrawal-list" ? "active" : ""
                    }
                    onClick={() => handleLinkClick("/withdrawal-list")}
                  >
                    <img width={"20"} src={withdraw} alt="rewicon" /> withdrawal
                    List
                  </Link> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Saidbar;
