import React from "react";

const Heading = () => {
  return (
    <div className="traceRoute-layout">
      <p className="traceRoute-heading">Swap AVTAR</p><p></p>
    </div>
  );
};

export default Heading;
