import React from "react";
import "./TermsPrivacy.css";
import { useNavigate } from "react-router-dom";

const TermsOfUsePage = () => {
    const navigate = useNavigate(); 
  return (
    <div className="container terms-privacy-page">
         <button className="back-button" onClick={() => navigate("/register")}>
        Back to homepage
      </button>
      <h1>Terms of Use</h1>
      <p>Welcome to Avtar Cryptocurrency Decentralized Exchange (DEx). By accessing or using our platform, you agree to comply with and be bound by the following terms of use. Please read these terms carefully before using our services.</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By using our platform, you agree to these terms and any future amendments. We reserve the right to modify these terms at any time, and it is your responsibility to review them periodically.</p>

      <h2>2. Use of Platform</h2>
      <p>Our platform allows users to trade cryptocurrencies in a decentralized manner. You must ensure that your use of our platform is compliant with the laws in your jurisdiction. Any fraudulent or illegal activity is strictly prohibited.</p>

      <h2>3. User Obligations</h2>
      <p>You agree to use the platform responsibly and not to engage in any behavior that compromises its security or functionality.</p>

      <h2>4. Disclaimer of Warranties</h2>
      <p>The platform is provided on an "as is" basis without any warranties. We do not guarantee uninterrupted or error-free services.</p>

      <h2>5. Limitation of Liability</h2>
      <p>Avtar DEx shall not be liable for any damages or losses resulting from your use of the platform.</p>

      <h2>6. Governing Law</h2>
      <p>These terms shall be governed by the laws of [Your Jurisdiction].</p>

      <p>If you have any questions about these terms, please contact us at support@avtar-dex.com.</p>
    </div>
  );
};

export default TermsOfUsePage;
