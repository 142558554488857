import React from "react";
import "./App.css";
import Connect from "./components/connect";
import Saidbar from "./components/saidbar";
import Staking from "./pages/Staking";
import Deshbord from "./components/deshbord";

import TermsOfUsePage from "./components/TermsOfUsePage";
import PrivacyPolicyPage from "./components/Privacypolicy";
import LevelRewards from "./pages/LevelRewards";
import DailyRewards from "./pages/DailyRewards";
import Withdrawal from "./pages/withdrawal";
import TeamBonus from "./pages/TeamBonus";
import LedarshipRewardList from "./pages/LedarshipReward";
import DairectIncome from "./pages/DairectIncome";
import { Route, Routes, useLocation } from "react-router-dom"; // Import useLocation hook
import Swap from "./components/Swap";
import Login from "./components/Login";
import Register from "./components/Register";
import Kyc from "./components/Kyc";
import {
  ProtectedAuthPages,
  ProtectedPages,
} from "./components/ProtectedRoutes";

function App() {
  const location = useLocation(); // Get current route location

  // Hide sidebar and connect button on login and register pages
  const hideSidebarAndConnect =
    location.pathname === "/login" || location.pathname === "/register" ||  location.pathname === "/terms-of-use" || location.pathname === "/privacy-policy";

  return (
    <React.Fragment>
      <div className="">
      
        {/* Conditionally render Saidbar and Connect components */}
        {!hideSidebarAndConnect && <Saidbar />}
        <div className="flex flex-col">
          {!hideSidebarAndConnect && <Connect />}
          <Routes>
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route element={<ProtectedAuthPages />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>
            <Route element={<ProtectedPages />}>
            
              <Route path="/" element={<Deshbord />} />
              <Route path="/:id" element={<Deshbord />} />
              <Route path="/staking" element={<Staking />} />
              <Route path="/level-rewards" element={<LevelRewards />} />
              <Route path="/daily-rewards" element={<DailyRewards />} />
              <Route path="/withdrawal-list" element={<Withdrawal />} />
              <Route path="/Kyc" element={<Kyc />} />
              <Route path="/team-bonus" element={<TeamBonus />} />
              <Route
                path="/LedarshipRewardList"
                element={<LedarshipRewardList />}
              />
              <Route path="/DairectIncome" element={<DairectIncome />} />
              <Route path="/Swap" element={<Swap />} />
            </Route>
          </Routes>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
