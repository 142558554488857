import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { BASE_URL } from "../constants";

const KYCSubmit = () => {
  const [passportNumber, setPassportNumber] = useState("");
  const [document, setDocument] = useState(null);
  const [error, setError] = useState("");
  const [isKYCSubmitted, setIsKYCSubmitted] = useState(false);

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/profile`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        
        if (response.data.success) {
          const userData = response.data.data;

          // Check if passportNumber exists
          if (userData.passportNumber) {
            setIsKYCSubmitted(true);
            setPassportNumber(userData.passportNumber);
            toast.info("You have already submitted your KYC.");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [passportNumber]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Validate file type
    if (file) {
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setError("Please upload a valid image file (JPEG or PNG).");
        setDocument(null); // Reset document state
        return;
      }
      setError(""); // Clear any previous errors
      setDocument(file); // Set the document if valid
    }
  };

  const handlePassportNumberChange = (e) => {
    setPassportNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!passportNumber || !document) {
      setError("Please fill out all fields and upload the required document.");
      return;
    }
    setError("");

    // Create a FormData object to hold the form data
    const formData = new FormData();
    formData.append("passportNumber", passportNumber);
    formData.append("document", document);

    try {
      // Send a POST request to the backend (assuming `multipart/form-data`)
      const response = await axios.post(`${BASE_URL}/users/kyc`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });

      // Show success message (Optional: using toast for notification)
      toast.success("KYC Submitted Successfully!");
      console.log(response.data); // Handle response as needed
      // Reset form after submission
      setPassportNumber("");
      setDocument(null);
      
    } catch (error) {
      console.log(error);
      
      // Handle error during form submission
      setError("Failed to submit KYC. Please try again.");
      console.error("KYC submission error:", error);
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="container pb-5 text-white mt-5">
      <h1 className="mb-4"></h1>
      {isKYCSubmitted ? (
        <div className="alert alert-info">
          You have already submitted your KYC with passport number: {passportNumber}.
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="passportNumber"
              className="form-label"
              style={{ fontSize: "2rem" }}
            >
              Passport Number
            </label>
            <input
              type="text"
              className="form-control"
              id="passportNumber"
              placeholder="Enter your passport number"
              value={passportNumber}
              onChange={handlePassportNumberChange}
              disabled={isKYCSubmitted} // Disable input if KYC is submitted
            />
          </div>
          <div className="my-3">
            <label
              htmlFor="document"
              style={{ fontSize: "2rem" }}
              className="form-label"
            >
              Upload Document
            </label>
            <input
              type="file"
              className="form-control"
              id="document"
              onChange={handleFileChange}
              accept=".png, .jpg, .jpeg" // Specify accepted file types
              disabled={isKYCSubmitted} // Disable file input if KYC is submitted
            />
          </div>
          {error && <div className="text-danger mb-3">{error}</div>}
          {!isKYCSubmitted && (
            <button type="submit" className="btn btn-primary p-2">
              Submit KYC
            </button>
          )}
        </form>
      )}
    </div>
  );
};

export default KYCSubmit;
