import { useContract, useContractWrite } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { toast } from "react-toastify";

const useStakeToken = () => {
  const { contract } = useContract(
    "0x8EA00F118a2b802B760dffB4567f33C8daf7101b"
  );
  const { mutateAsync: buyTokens } = useContractWrite(contract, "buyTokens");

  const stakeToken = async (usdtAmount, getParent, id) => {
    try {
      let ref =
        getParent === "0x0000000000000000000000000000000000000000"
          ? id
          : getParent;
      console.log("usdtAmt", usdtAmount);
      console.log("id", id);
      console.log("parent", getParent);

      const data = await buyTokens({ args: [usdtAmount, ref] });
      console.info("Contract call success", data);
      toast.success("You have successfully purchased AVTAR tokens", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error("Something Went Wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Contract call failure", err);
    }
  };

  return stakeToken;
};

export default useStakeToken;
