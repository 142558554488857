import React, { useState } from "react";
import avtarIcon from "../image/traceIcons/avtar.png";

const DropDown = ({ selectedIcon, selectedName }) => {
  const dropdownWidth = "200px";

  return (
    <div style={{ position: "relative", width: "150px", padding: "0 10% 0 0" }}>
      <div
        className="widthdrow approve-token-new"
        style={{
          display: "flex",
          width: dropdownWidth,
          padding: "1.5% 0% 0% 0%",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #494b51",
          borderRadius: 14,
          cursor: "default", // No dropdown functionality
          height: "35px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <img src={selectedIcon} style={{ height: "1.6rem", marginLeft: "10%" }} />
          <p style={{ paddingTop: "0.6rem", marginLeft: "10%" }}>{selectedName}</p>
        </div>
      </div>
    </div>
  );
};

const SwapComponent = () => {
  const [selectedToken, setSelectedToken] = useState("AVTAR");
  const [selectedIcon] = useState(avtarIcon);

  return (
    <div>
      <DropDown
        selectedName={selectedToken}
        selectedIcon={selectedIcon}
      />
      {/* Other elements like the swap button, etc. */}
    </div>
  );
};

export default SwapComponent;
