import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import { BASE_URL } from "../constants";
import toast from "react-hot-toast";


import './login.css'; // Add custom CSS for styling

const Login = () => {
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(true);
  const [resetError, setResetError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleForgotPasswordOpen = () => setShowForgotPasswordModal(true);
  const handleForgotPasswordClose = () => {
    toast.success("Password successfully Changed !");
    setShowForgotPasswordModal(false);
    setEmailSent(false);
    setOtpDisabled(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(5, "Password must be at least 5 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const { data, status } = await axios.post(
          `${BASE_URL}/users/login`,
          values
        );
        if (status === 200) {
          toast.success("Login Successful");
          localStorage.setItem("token", data.data.authToken);
          window.location.reload();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Login Failed");
        console.error("Login failed", error);
      }
    },
  });

  return (
    <div className="login-container">
      {/* Left section for form */}
      <div className="logo-container">
      <img src="/logonew.png" alt="Logo" className="logo" />
      </div>
      <div className="login-form-section">
        <form className="login-form" onSubmit={formik.handleSubmit}>
          <h1>Welcome to Avtar!</h1>
          <p>Buy Avtar & Baaz easily on Avtarexchange..</p>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              {...formik.getFieldProps("email")}
              className={formik.touched.email && formik.errors.email ? "error" : ""}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="error-message">{formik.errors.email}</div>
            )}
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              {...formik.getFieldProps("password")}
              className={formik.touched.password && formik.errors.password ? "error" : ""}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="error-message">{formik.errors.password}</div>
            )}
          </div>
          <div className="form-options">
            <label>
              <input type="checkbox" /> Remember me
            </label>
            <button type="button" onClick={handleForgotPasswordOpen} className="forgot-password-link">
              Forgot your password?
            </button>
          </div>
          <button type="submit" className="login-button">Log In</button>
          <div className="alternative-login">
            {/* <p>Or, log in with</p>
            <button className="google-login">Sign up with Google</button> */}
          </div>
          <p>
            Don’t have an account? <Link to="/register">Register here</Link>
          </p>
        </form>
      </div>

      {/* Right section for abstract design */}
      <div className="login-design-section">
        {/* Include decorative images and patterns */}
      </div>
    </div>
  );
};

export default Login;
