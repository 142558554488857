import React, { useState } from "react";
import infoIcon from "../image/info_icon.png";
import dropdownicon from "../image/dropdownicon.png";

const DropDown2 = ({ icon, Name }) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownWidth = "200px";

  return (
    <div style={{ position: "relative", width:"150px", padding:"0 10% 0 0" }}>
      <div
        className="widthdrow approve-token-new"
        style={{
          display: "flex",
          width: dropdownWidth,
          padding: "1.5% 4% 0 3%",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #494b51",
          borderRadius: 14,
          cursor: "pointer",
          height:"35px",
          width:"100%"
        }}
        onClick={() => setOpen(!isOpen)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <img src={icon} style={{ height: "1.2rem" , marginLeft:"12%" }} />
          <p style={{ paddingTop: "0.6rem" , marginLeft:"20%"}}>{Name}</p>
        </div>
       
      </div>
    </div>
  );
};

export default DropDown2;
