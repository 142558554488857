import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"; // For API calls
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import { BASE_URL } from "../constants";
import toast from "react-hot-toast";
import './register.css';


const Register = () => {
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState(""); // Store email here
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
 
  
  const navigate = useNavigate();

  // Handle form submission for signup
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      agreeToTerms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
      agreeToTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post(`${BASE_URL}/users/register`, {
          email: values.email,
        });
        setEmail(values.email);
        setUserId(response.data.userId);
        setOtpModal(true);
        toast.success(response.data.message || "Otp sent successfully!");
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || "Failed to create new account!");
        toast.error(error?.response?.data?.message || "Failed to create new account!");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const { status, data } = await axios.post(`${BASE_URL}/users/verify`, {
        email: email,
        password: formik.values.password,
        otp: otp,
      });
      if (status === 200) {
        toast.success("OTP has been verified successfully!");
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "OTP verification failed.");
      setError(error?.response?.data?.message || "OTP verification failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex vh-100">
        <div className="logo-container-r">
      <img src="/logonew.png" alt="Logo" className="logo" />
      </div>
      {/* Left Section - Form */}
      
      <div className="col-md-7 p-5 d-flex flex-column justify-content-center">
        <Form onSubmit={formik.handleSubmit} className="w-60 mx-auto">
          <h1 className="mb-4" style={{ color: "#333333", fontWeight: "600" }}>Welcome back!</h1>
          <p className="mb-4">Enter to get unlimited access to data & information.</p>
          
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              className={formik.touched.password && formik.errors.password ? "is-invalid" : ""}
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="invalid-feedback">{formik.errors.password}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
             type="checkbox"
             label={
               <span>
                 I agree to the{" "}
                 <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                   Terms of Use
                 </Link>{" "}
                 and{" "}
                 <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                   Privacy Policy
                 </Link>
               </span>
             }
             className={formik.touched.agreeToTerms && formik.errors.agreeToTerms ? "text-danger" : ""}
             {...formik.getFieldProps("agreeToTerms")}
           />
            {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
              <div className="text-danger">{formik.errors.agreeToTerms}</div>
            )}
          </Form.Group>

          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={loading}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Sign up"}
          </button>

          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

          <p className="mt-4 text-center">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </Form>
      </div>

      <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Terms of Use content goes here...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTermsModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Privacy Policy content goes here...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPrivacyModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Right Section - Decorative Background */}
      <div className="col-md-7 d-none d-md-block" style={{ backgroundColor: "#373738" }}>
        {/* <div style={{ backgroundImage: 'url(/path/to/your-pattern.png)', backgroundSize: 'cover', height: '100%' }}></div> */}
      </div>

      {/* OTP Modal */}
      <Modal show={otpModal} onHide={() => setOtpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the OTP sent to your email.</p>
          <Form.Control
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOtpModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleOtpSubmit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Verify OTP"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Register;
