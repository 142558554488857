import React from "react";
import reloadIcon from "../image/iconreload.png";
import swapicon from "../image/swapicon.png";
import { Contract } from "ethers";
import {
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
  checkClientIdOrSecretKey,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { parse } from "url";


const InfoBox = () => {
  const { contract } = useContract(
    "0x1bE7fC411CC60a2Ba28483Fb510eC8D0873CC1E8"
  );

  const { data: Avtarrate, isLoading: avtarrateloading } =
    useContractRead(contract, "avtarRate");

  return (
    <div
      style={{
        width: "100%",
        padding: "1.5% 1% 0 1%",
        justifyContent: "center",
        marginTop: "1%",
        padding: "2%",
        alignItems: "center",
        border: "1px solid #494b51",
        borderRadius: 14,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontSize: "0.5rem",
          marginTop:"10px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "0.5rem",
            padding:"0 3% 0 3%"
          }}
        >
          <p style={{ fontSize: "14px",fontWeight:"bold" }}>Rate</p>
          {/* <p
            style={{
              fontSize: "0.8rem",

              textUnderlineOffset: "3px",
            }}
          >
            Minimum Recieved
          </p>
          <p
            style={{
              fontSize: "0.8rem",

              textUnderlineOffset: "3px",
            }}
          >
            Price Impact
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            padding:"0 3% 0 3%"
          }}
        >
          <div style={{ display: "flex", gap: "2px" }}>

            <p style={{ fontSize: "14px",fontWeight:"bold", color: "white" }}>
              1 Avtar = {(Avtarrate/1000000000000000000).toFixed(3)}{" "}USDT 
            </p>{" "}
          </div>

          {/* <p style={{ fontSize: "0.8rem", color: "white" }}>0.394732 USDT</p>
          <p style={{ fontSize: "0.8rem", color: "white" }}>0.16%</p> */}
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
