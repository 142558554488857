import React, {useEffect, useState } from "react";
import Heading from "./Heading";
import DropDown from "./DropDown";
import InfoBox from "./InfoBox";
import icon1 from "../image/icon.png";
import axios from "axios";
import avtar from "../image/traceIcons/avtar.png";
import baaz from "../image/traceIcons/baaz.png";
import DropDown2 from "./DropDown2";
import swapicon from "../image/traceIcons/swap.png";
import useStakeToken from "../hooks/useStakeToken";
import { Contract } from "ethers";
import "react-toastify/dist/ReactToastify.css";
import { FadeLoader } from "react-spinners";
import { renderToString } from "react-dom/server";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BASE_URL } from "../constants";
import greenarrow from "../image/greenarrow.svg";
import demo from "../image/demo.svg";
import copy from "../image/Vector (75).png";
import {
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
  checkClientIdOrSecretKey,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { parse } from "url";




const ApproveUSDT = () => {
  const [approveAmt, setApproveAmt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // For success state
  const address = useAddress();
  

  const { contract } = useContract(
    "0x1bE7fC411CC60a2Ba28483Fb510eC8D0873CC1E8"
  );
  
  const { contract: AvtarContract } = useContract(
    "0x6E291276d62F66404010B34A5cdFe8cd7244e054"
  );

  const [passportNumber, setPassportNumber] = useState("");
  const [document, setDocument] = useState(null);
  const [error, setError] = useState("");
  const [isKYCSubmitted, setIsKYCSubmitted] = useState(false);

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/profile`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        
        if (response.data.success) {
          const userData = response.data.data;

          // Check if passportNumber exists
          if (userData.passportNumber) {
            setIsKYCSubmitted(true);
            setPassportNumber(userData.passportNumber);
            //toast.info("You have already submitted your KYC.");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [passportNumber]);

  const { mutateAsync: approve } = useContractWrite(AvtarContract, "approve");

  const approveTokens = async () => {
    try {
      setIsLoading(true); // Start progress bar
      setIsSuccess(false); // Reset success state
      let spender = "0x1bE7fC411CC60a2Ba28483Fb510eC8D0873CC1E8"; // Contract address
      let approveAmount = ethers.utils.parseEther(approveAmt);
      const data = await approve({ args: [spender, approveAmount] });
      console.info("Contract call success", data);
      toast.success("Successfully approved USDT!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSuccess(true); // Show success after transaction
    } catch (err) {
      toast.error("Approve Failed!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Contract call failure", err);
    } finally {
      setIsLoading(false); // Stop loading
      setApproveAmt("");
    }
  };

  // Reload the page 1 second after the button turns green
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.reload(); // Reload the page
      }, 3000); // 1 second delay
    }
  }, [isSuccess]);

  return (
    <div
      style={{
        width: "100%",
        padding: "4% 2% 2% 6%",
        justifyContent: "center",
        alignItems: "center",
        border: "0px solid #494b51",
        background: "#22262f",
        borderRadius: 14,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}></div>
      <h3 style={{ fontFamily: "Plus Jakarta Sans", color: "#fff", fontSize: "17px", fontWeight:"bold", marginBottom: "1rem" }}>
        Approve AVTAR to swap
      </h3>
      <p style={{ color: "#999", fontSize: "0.9rem" }}>
        NOTE: Approve amount of AVTAR you want to Swap
      </p>
      <input
        type="number"
        onChange={(el) => {
          setApproveAmt(el.target.value);
        }}
        placeholder="Enter USDT Amount"
        value={approveAmt}
        style={{
          width: "95%",
          height: "2.5rem",
          padding: "0.5rem",
          borderRadius: "0.5rem",
          backgroundColor: "#333",
          border: "none",
          color: "#fff",
          marginBottom: "1rem",
          outline: "none",
        }}
      />
      <button
        onClick={approveTokens}
        style={{
          width: "95%",
          color: "#fff",
          marginBottom:"12px",
          padding: "1rem",
          borderRadius: "10px",
          border: "1px solid #494b51",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          background: isSuccess
            ? "#4CAF50" // Green on success
            : isLoading
            ? "linear-gradient(90deg, #007BFF 50%, #1c1f28 50%)" // Blue progress bar
            : "#1c1f28", // Normal button
          backgroundSize: isLoading ? "200% 100%" : "100% 100%",
          backgroundPosition: isLoading ? "right center" : "left center",
          transition: "background-position 2s ease-out", // Smooth transition
          position: "relative", // To ensure proper layering
        }}
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? "Approving AVTAR..." : "Approve USDT"}
        {!isLoading && (
          <img
            src={greenarrow}
            alt="Approve Arrow"
            style={{ width: "1rem", height: "1rem" }}
          />
        )}
      </button>
      <ToastContainer />
    </div>
  );
};

const Swap = () => {
  const [isSwapped, setIsSwapped] = useState(false);
  const [dropdownState, setDropDownState] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Avtaramount, setAvtarAmt] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [document, setDocument] = useState(null);
  const [error, setError] = useState("");
  const [isKYCSubmitted, setIsKYCSubmitted] = useState(false);

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/profile`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        
        if (response.data.success) {
          const userData = response.data.data;

          // Check if passportNumber exists
          if (userData.passportNumber) {
            setIsKYCSubmitted(true);
            setPassportNumber(userData.passportNumber);
            // toast.info("You have already submitted your KYC.");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [passportNumber]);

  const address = useAddress();

  const { contract } = useContract(
    "0x1bE7fC411CC60a2Ba28483Fb510eC8D0873CC1E8"
  );
  
  const { contract: AvtarContract } = useContract(
    "0x6E291276d62F66404010B34A5cdFe8cd7244e054"
  );
  const { data: walletBal, isLoading: walletBalLoading } = useTokenBalance(
    AvtarContract,
    address
  );

  const { data: Avtarrate, isLoading: avtarrateloading } =
    useContractRead(contract, "avtarRate");

  const { mutateAsync: swapAvtar, isLoading: isavtarswapped } =
  useContractWrite(contract, "swapAvtar");

  const swapAvtarTokens = async () => {
    setIsLoading(true);
    try {
      let ref;
      if (ref === "0x0000000000000000000000000000000000000000") {
        ref = ref;
      } else {
        ref = ref;
      }
      let Avtaramount = ethers.utils.parseEther(amount);
      
      const data = await swapAvtar({ args: [Avtaramount] });
      console.info("contract call success", data);
  
      toast.success("Successfully swapped AVTAR tokens", {
        position: toast.POSITION.TOP_RIGHT,
      });
  
      // Wait for 3 seconds before reloading the page
      setTimeout(() => {
        window.location.reload();
      }, 3000); // 3 seconds delay
  
    } catch (err) {
      toast.error("Something Went Wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("contract call failure", err);
    } finally {
      setAvtarAmt("");
      setIsLoading(false);
    }
  };
  

  const handleSwap = () => {
    setIsSwapped(!isSwapped);
  };

  const handleDropDownChange = (selectedName) => {
    setDropDownState(selectedName);
    let usdtAmount, getParent;
    if (selectedName === "AVTAR") {
      usdtAmount = amount * 0.100;
      getParent = "0x8EA00F118a2b802B760dffB4567f33C8daf7101b";
    } else {
      usdtAmount = amount * 0.100;
      getParent = "0x7d185d79b7D28be5A9a9F8F1dCE6AAb7e5462c83";
    }
   
  };

  
  const firstDiv = (
    <div
      style={{
        width: "100%",
        padding: "2% 2% 2% 6%",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #494b51",
        background: "#22262f",
        borderRadius: 14,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{  }}>
          <p
          style={{
            color:"#999", 
            fontSize: "0.8rem",
            textUnderlineOffset: "3px",
          }}
          >Balance  :{"  "}{walletBal?.displayValue}{" "} AVTR  </p>
          <input
            type="number"
            // value={amount}
            placeholder="Enter Amount"
            value={amount}
            style={{
              marginTop: "28%",
              height: "2rem",
              width: "10rem",
              borderTop: "0px solid #000",
              borderBottom: "0px solid #000",
              borderLeft: "0px solid #000",
              border: "1px solid #494b51",
              background: "#22262f",
              borderRadius: "10px",
              paddingLeft: "0.01rem",
              color: "white",
              borderRight: "0px solid #000",
              margin: 0,
              border:"none",
              outline: 'none',
              fontSize:"18px",
              fontWeight:"bold"
             
            }}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" , marginTop:"5%" }}>
          
          <div style={{ display: "flex", alignItems: "center", gap: "0.8%" }}>
            <DropDown
              icon={avtar}
              selectedName="AVTAR"
              handleDropDownChange={handleDropDownChange}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const secondDiv = (
    <div
      style={{
        width: "100%",
        padding: "2% 2% 0% 6%",
        justifyContent: "center",
        marginTop: "1%",
        alignItems: "center",
        border: "1px solid #494b51",
        background: "#22262f",
        borderRadius: 14,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: "0.8rem",
             
              textUnderlineOffset: "3px",
              marginTop:"2%"
            }}
          >
            Est. Output
          </p>
          <p style={{ fontSize: "1.2rem", fontWeight:"bold" }}>
            {dropdownState === "AVTAR"
              ? (amount * 0.1).toFixed(3)
              : (amount * 0.1).toFixed(3)}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              marginLeft: "80%",
            }}
          >
            
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginTop:"10%"
            }}
          >
            <DropDown2
              icon="https://www.blocknative.com/hubfs/USDT.png"
              Name="USDT"
            />
          </div>
        </div>
      </div>
    </div>
    

    
  );
  return (
    <div className="centerPos">
      
      <div className="content">
      <ToastContainer />
        {isLoading && (
          <div className="loader_new">
            <FadeLoader color="#36d7b7" />
          </div>
        )}
        <div className="traceRoute">
          <Heading />
          <p className="traceRoute-para">
            Instantly swap Avtar tokens at superior price
          </p>
          <div className="innerSection">
            <div style={{ position: "relative" }}>
              {isSwapped ? secondDiv : firstDiv}
              <img
                src={swapicon}
                style={{
                  borderRadius: "100%",
                  position: "absolute",
                  top: "21%",
                  left: "45%",
                  height: "2rem",
                  width: "2rem",
                  cursor: "pointer",
                }}
              />
              {isSwapped ? firstDiv : secondDiv}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  justifyItems: "center",
                }}
              >
               
                {/* <select
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    border: "none",
                  }}
                >
                  <option>0.5%</option>
                  <option>1.5%</option>
                  <option>2.5%</option>
                </select> */}
              </div>
              <InfoBox />
              <button
                // disabled={isKYCSubmitted}
                onClick={swapAvtarTokens}
                style={{
                  width: "100%",
                  backgroundColor: "rgb(24,28,37)",
                  borderRadius: "10px",
                  border: "none",
                  marginTop: "1rem",
                  height: "3rem",
                  marginBottom: "2rem",
                }}
              >
                Swap
              </button>
            </div>
            <div style={{ flex: 3 }}>
              <ApproveUSDT />
            </div>
          </div>
         
        </div>
      </div>
      
    </div>
    
  );
};



export default Swap;
