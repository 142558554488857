import React from "react";
import "./TermsPrivacy.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicyPage = () => {
    const navigate = useNavigate(); 
  return (
    <div className="container terms-privacy-page">
        <button className="back-button" onClick={() => navigate("/register")}>
        Back to homepage
      </button>
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us at Avtar DEx. This privacy policy explains how we collect, use, and protect your information.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect basic user information such as email addresses for account registration and communication purposes. We do not store any payment information.</p>

      <h2>2. Use of Information</h2>
      <p>Your information is used solely to provide and improve our services. We do not sell or share your information with third parties, except as required by law.</p>

      <h2>3. Cookies</h2>
      <p>We may use cookies to enhance your experience. These cookies help us understand user behavior and improve platform functionality.</p>

      <h2>4. Data Security</h2>
      <p>We implement industry-standard security measures to protect your data. However, we cannot guarantee absolute security against potential breaches.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to request access, correction, or deletion of your personal data at any time. Please contact us at privacy@avtar-dex.com for assistance.</p>

      <h2>6. Changes to This Policy</h2>
      <p>We may update this policy periodically. Changes will be notified on our platform, and your continued use will signify your acceptance of the updated terms.</p>

      <p>If you have any questions about this privacy policy, please contact us at privacy@avtar-dex.com.</p>
    </div>
  );
};

export default PrivacyPolicyPage;
